.upanyas-secondary {
  border: 1px solid #fa7925;
  padding: 8px 21px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s;
  font-size: 14px;
  color: #fa7925;
  background: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px #0000000b;
}

.upanyas-primary {
  border: 1px solid #fa7925;
  padding: 8px 21px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s;
  font-size: 14px;
  color: #fff;
  background: #fa7925;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px #0000000b;
}
.upanyas-gray {
  background: #efefef;
  border: 1px solid #efefef;
  color: #fa7925;
  padding: 9px 21px;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 14px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px #0000000b;
}

.upanyas-secondary:focus,
.upanyas-primary:focus,
.upanyas-gray:focus {
  outline: none;
}
a.upanyas-primary:hover {
  color: #fff;
}

a.upanyas-secondary:hover,
a.upanyas-gray:hover {
  color: #fa7925;
}
.upanyas-secondary:hover {
  outline: none;
  background-color: #eee;
}

.upanyas-primary:hover {
  background-color: #cb5508;
}

.upanyas-gray:hover {
  background-color: #d4d4d4;
}
