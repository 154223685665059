@import '../Style/Typography.scss';
// $grid-breakpoints: (xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 1025px,
//   xl: 1200px,
// );

.SearchLogo {
  height: auto;
  width: 35px;
  text-align: center;
  display: block;
}

@keyframes slideup {
  0% {
    transform: translateY(20px);
    opacity: 0;
    display: block;
  }
  50% {
    transform: translateY(10px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.search-bar-container {
  width: 450px;
  height: 50px;
  margin-left: 70px;

  .seacrhbox {
    display: block;
    background-color: #f2f2f2;
    border-style: none;
    border-radius: 50px;
    font-size: 14px;
    background-image: url('../Icons/search-bar-icon.svg');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 20px;
    text-indent: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .search-suggestions {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
    border-radius: 0px 0px 12px 12px;
    top: 55px;

    &.show {
      animation: slideup 100ms ease-in;
    }

    &.hide {
      display: none;
    }

    p.heading {
      color: #4f4f4f;
      font-weight: 500;
      font-size: 14px;
      border-bottom: 1px solid #f2f2f2;
    }
    .recent-searches-item {
      font-size: 14px;
      padding: 8px 12px;
      transition: all 0.3s;
      cursor: pointer;
      margin-bottom: 4px;

      img {
        visibility: hidden;
      }

      &:hover {
        background-color: #f2f2f2;

        & img {
          visibility: visible;
        }
      }
    }
  }
}

.seacrhbox:focus {
  outline: none;
}

.searchContent {
  margin-top: 130px;
  padding-left: 25px;
  padding-right: 25px;
}

.search-backdrop {
  height: calc(100% - 70px);
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 70px;
  background: rgba(255, 255, 255, 0.5);
}

.searchCard {
  background-repeat: no-repeat;
  background-size: 32px;
  background-position-x: 9px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  padding: 17px;
  padding-left: 49px;
  padding-right: 13px;
  border-radius: 6px;
}

.SearchImg {
  height: 148px;
  filter: drop-shadow(0px 3px 2px #aaa);
  border-radius: 6px;
}

.SearchTitle {
  // margin-top: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 20px;
}

.SearchTag {
  background-color: #ffccaa;
  width: 100px;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 0;
  right: 15px;
}

.SearchRead {
  float: right;
  margin: auto;
  padding: 11px 22px;
  text-align: center;
  line-height: 0.82rem;
  position: absolute;
  bottom: 15px;
  right: 30px;
}

.SeacrhAdd {
  float: right;
  margin: auto;
  padding: 11px 22px;
  text-align: center;
  line-height: 0.82rem;
  position: absolute;
  bottom: 15px;
  right: 130px;
}

.Author.SearchTag {
  background-color: #acc8ff;
}

.Novel.searchCard.media {
  background-image: url('../Images/noveltag.svg');
}

.Author.searchCard.media {
  background-image: url('../Images/authortag.svg');
}

.Poem.searchCard.media {
  background-image: url('../Images/poemtag.svg');
}

.Short.searchCard.media {
  background-image: url('../Images/storytag.svg');
}

.Short.SearchTag {
  background-color: #d9f3a2;
}

.Poem.SearchTag {
  background-color: #f8a1a1;
}

.Novel.searchCard.media > .media-body {
  overflow: hidden;
}

.SearchTitle,
.SearchAuthor {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobilesearchbox {
  display: none;
  margin-top: 110px;
  padding-left: 23px;
  padding-right: 23px;
}

.mobilesearchinput {
  background-color: #f6f6f6;
  width: 100%;
  height: 42px;
  border-style: none;
  border-radius: 6px;
  outline: none;
  padding: 26px 17px;
}

.searchbutton {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .mobilesearchbox {
    display: block;
  }

  .searchContent {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  button.upanyas-primary.searchbutton {
    margin-top: 20px;
    height: 45px !important;
  }
}

// @media (max-width: 500px) {}
@media (max-width: 500px) {
  .searchCard {
    display: block;
    padding-bottom: 30px;
    padding-top: 40px;
    padding-left: 13px;
    text-align: center;
  }

  .SearchImg {
    display: block;
    margin: auto !important;
    text-align: center;
  }

  .SearchTitle {
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .SearchRead,
  .SeacrhAdd {
    float: none;
    margin: auto;
    margin-right: 15px;
    padding: 11px 22px;
    text-align: center;
    line-height: 0.82rem;
    position: relative;
    display: block;
    bottom: 0;
    width: 100%;
    right: 0;
    border: 1px solid #fa7925;
    margin-top: 20px;
  }
}
.search-page-bar-parent {
  max-width: 550px;
  display: flex;
  align-items: center;
  img {
    margin-left: -55px;
  }
  .search-page-bar {
    width: 100%;
    background-image: url(../Icons/searchActive.svg);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 20px;
    background-size: 20px;
    padding-left: 50px;
    padding-right: 50px;
    border-color: #bf3e3e;
    box-shadow: 0px 4px 7px 2px rgb(211 73 73 / 15%);
  }
}

.search-page-title-tabs {
  h6 {
    color: #9d9d9d;
  }
}

@keyframes grow {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.books-header {
  border-bottom: 1px solid #e0e0e0;
  .search-filter {
    .mtrd-input {
      width: auto;
      height: auto;
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 25px;
      position: relative;
      padding-right: 30px;
      border-width: 1px;
      border-color: #e0e0e0;
      &::before {
        background-size: 20px;
        width: 40px;
        height: 40px;
      }
    }
    .mtrd-select-items {
      font-size: 14px;
      padding: 4px 0px;
      top: 48px;
      width: 130px;
      right: 0;
      border-width: 1px;

      &.show {
        animation: grow 100ms ease-in-out forwards;
      }

      .mtrd-select-item {
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}
.authors-list {
  .author-card {
    border-bottom: 1px solid #e0e0e0;
    .author-info {
      p {
        margin-bottom: 0;
      }
      img {
        height: 54px;
        width: 54px;
        object-fit: cover;
        border-radius: 50%;
      }
      .author-name {
        font-size: 16px;
        font-weight: 500;
      }
      .author-username {
        font-size: 14px;
        font-weight: 500;
        color: #9d9d9d;
      }
    }
  }
}
