@import '../../Style/Color.scss';
@import '../../Style/Typography.scss';

.heading-box {
  background-color: #202020;
  height: 500px;
  background-image: url(../../Images/HomepageJumboImage.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  @media screen and (max-width: 576px) {
    height: 450px;
    background-size: cover;
    background-position-x: left;
    background-image: none;
  }

  .col-lg-6 {
    margin-top: 80px;

    @media screen and (max-width: 1024px) {
      margin-top: 60px;
    }
  }

  .jumboContent {
    margin-left: 100px;
    margin-right: 85px;

    @media screen and (max-width: 1024px) {
      margin-left: 90px;
      margin-right: 90px;
    }

    @media screen and (max-width: 767px) {
      margin-left: 50px;
      margin-right: 50px;
    }

    @media screen and (max-width: 576px) {
      margin-left: 30px;
      margin-right: 30px;
    }

    p {
      color: #ffffff;
      line-height: 112.5%;
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 50px;

      @media screen and (max-width: 767px) {
        font-size: 28px;
      }
      @media screen and (max-width: 576px) {
        font-size: 24px;
      }
      @media screen and (max-width: 380px) {
        font-size: 22px;
      }
    }

    .searchInput {
      .d-flex {
        input.form-control {
          margin-right: 20px;
          height: 100%;
          box-shadow: 0px 24px 31px -12px rgba(250, 121, 37, 0.22);
          padding: 11px 21px;

          @media screen and (max-width: 576px) {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }

        .upanyas-primary {
          @media screen and (max-width: 576px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 576px) {
          display: block !important;
        }
      }
    }
  }
}
