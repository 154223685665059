@import '../../Style/Typography.scss';

// MTRD

.mtrd-modal {
  @media screen and (min-width: 576px) {
    &.genre-modal {
      max-width: 530px !important;
    }
  }
  & > .modal-content {
    border-radius: 20px;
    border: none;

    & > .modal-body {
      padding-left: 30px;
      padding-right: 30px;
    }

    & > .modal-header,
    & > .modal-footer {
      border: none;
      padding-left: 30px;
      padding-right: 25px;
    }

    & > .modal-footer {
      padding-bottom: 20px;
    }

    & > .modal-header {
      padding-top: 20px;
      padding-bottom: 0px;
    }
  }
}

.genres-container {
  .genre-tag {
    padding-right: 16px;
    svg {
      margin-left: 8px !important;
      background: #ffe7e7;
      border-radius: 50%;
      padding: 3px;
      box-sizing: content-box;
    }
  }
}

.img-and-remove-button-holder {
  img {
    width: 185px;
    height: 262px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 7px 15px -11px black;
  }
}
