@import '../../Style/Typography.scss';

.customButton {
  outline: none;
  width: 100%;
  text-align: center;
  padding: 2.8rem 0;
  margin-bottom: 1rem;
  height: 8rem;
  border: white;
  border-radius: 0.7rem;
  @include subtitle(white, 1.4rem, 600);
}
