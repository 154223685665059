// Wirteup Modal
.writeNew .modal-content {
  border-radius: 20px;
  border: none;
}

.wtModalHeading {
  font-size: 25px;
}

.writeContinue {
  font-size: 18px;
}

.writeContinue span {
  color: #fa7925;
}

.wtCategory .imgCaption {
  color: #000;
}
