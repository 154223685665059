@import './Style/Typography.scss';
@import './Style/Color.scss';

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 1025px,
//   xl: 1350px,
// );

@import 'bootstrap/scss/bootstrap.scss';

/* login page css from 2 - 104*/
html,
body,
#root {
  height: 100%;
}

.containers {
  min-height: 100%;
  margin: auto;
  overflow: hidden;
  z-index: 10;

  &.typewriter,
  &.reader,
  &.admin {
    padding-bottom: 0px;
  }

  &.detail {
    padding-bottom: 60px;
  }
}
::selection {
  background: rgba(250, 121, 37, 0.36);
}
.display-inline-block {
  display: inline-block;
}

a {
  text-decoration-line: none !important;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.topnav {
  background-color: rgb(255, 255, 255);
  position: fixed;
  width: 100%;
  height: 50px;
}

#profileDropdown {
  cursor: pointer;
  user-select: none;
}

.nav-dropdown .dropdown-item {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 14px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
}

.nav-dropdown .dropdown-item:last-of-type {
  box-shadow: none;
}

.dropdown-item {
  cursor: pointer;
}

.mouse-pointer {
  cursor: pointer;
}

.primary-color {
  color: #fa7925 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.dropdown-item img {
  width: 17px;
  margin-top: -3px;
  margin-right: 12px;
}

.nav-dropdown.dropdown-menu {
  width: 290px;
  box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
}

.left {
  background-color: white;
  // height: 100vh;
  // width: 100%;
  overflow-y: auto;
}

.rights {
  background-color: #fdf3ec;
  height: 100%;
  width: 100%;
  border-radius: 30px 0px 0px 30px;
  // margin-left: 60%;
}

.logo {
  margin-left: 30px;
  margin-top: 30px;
}

.text {
  @include heading2();
  text-align: center;
  margin-top: 50px;
}

.swiper-button-next,
.swiper-button-prev {
  top: 40% !important;
  width: 19px !important;
  height: auto !important;
  font-weight: bolder;
  color: #000 !important;
  // margin-top: -25px;
  padding: 15px;
  box-sizing: content-box;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0 !important;
}
img.swiper-button-next {
  transform: rotate(180deg);
}
.font-weight-bold {
  font-weight: 600 !important;
}
// .SideLogo {
//   /* margin: 180px 10px 10px 180px; */
// }

.forget {
  display: flex;
  justify-content: flex-end;
  margin-left: 250px;
  margin-top: 35px;
}

.btn {
  background-color: white;
  // padding-left: 0px;
  @include normalText(#fa7925);
}

.button {
  outline: none;
  width: 100%;
  padding: 9px;
  background-color: #fa7925;
  color: white;
  border: white;
  border-radius: 4px;
  margin-top: 20px;
  font-weight: bold;
}

.signlink {
  margin-left: 250px;
  margin-top: 15px;
}

.image {
  margin-left: 10px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.imagef {
  margin-left: 10px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.login {
  text-align: center;
  margin-top: 28px;
  font-size: 14px;
  font-weight: 500;
}

.content {
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.wrapper {
  max-height: 100vh;
  overflow-y: hidden;
  display: flex;
  overflow-x: auto;
  margin-top: 50px;
}

.wrapper .box {
  min-width: 110px;
  height: 100%;
  text-align: center;
}

.box {
  margin: auto;
}

.list-box {
  width: 200px;
  margin: auto;
}

.img {
  width: 75%;
  height: 75%;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.sub {
  @include normalText();
}

.title {
  color: black;
  font-weight: bold;
  margin-top: 10px;
}

.author {
  opacity: 75%;
  margin-bottom: 10px;
}

.skeletion {
  .row {
    display: flex;
    justify-content: center;
  }
}

.adsd {
  margin: 50px 50px auto 50px;
}

.ads {
  width: 100%;
  height: 75%;
}

.sliderparent > h1 {
  margin-left: 40px;
}

.swiper-container {
  width: 100%;
}

// .swiper-button-next,
// .swiper-button-prev {
//   font-weight: bolder;
//   color: #000 !important;
//   // margin-top: -25px;
//   padding: 15px;
//   box-sizing: content-box;
// }

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  color: transparent !important;
}

// ContentCard
.ContentCardBig > .ContentCardTitle {
  font-weight: 600;
  font-size: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.ContentCard {
  margin-bottom: 20px;
}

.ContentCard > .m-auto > .ContentCardTitle {
  font-size: 17px;
  color: #3b5260;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.ContentCardBig > .ContentCardAuthor {
  font-size: 17px;
}

.ContentCard > .m-auto > .ContentCardAuthor {
  font-size: 15px;
  color: #b9c6ce;
  margin-bottom: 0px;
}

.ContentCardBig .ContentCardImage {
  width: 221px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px 2px #0000001c;
  height: 312px;
  object-fit: cover;
  @media (max-width: 576px) {
    width: 170px;
    height: 240px;
  }
}

.ContentCard .ContentCardImage {
  width: 136px;
  border-radius: 10px;
  height: 192px;
  object-fit: cover;
  -o-object-fit: cover;
  box-shadow: 0px 10px 20px -7px rgba(0, 0, 0, 0.15);
  @media (max-width: 576px) {
    width: 119px;
    height: 168px;
  }
}

.ContentCardBigParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.max-width {
  max-width: 1400px;
}

@media (max-width: 767px) {
  .ContentCardBig {
    transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    margin-top: 30px;
  }

  .ContentCardCol:nth-child(7),
  .ContentCardCol:nth-child(8) {
    display: none;
  }

  .showMoreParent {
    padding-right: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ContentCardCol:nth-child(7),
  .ContentCardCol:nth-child(8),
  .ContentCardCol:nth-child(6),
  .ContentCardCol:nth-child(5) {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1399px) {
  .ContentCardCol:nth-child(7),
  .ContentCardCol:nth-child(8) {
    display: none;
  }
}

.leftCardContainerBlue {
  background: #ecf0ff;
}

.show-more {
  color: #4e4e4e;
  font-weight: bold;
  text-decoration-line: underline;
}

.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 300ms, transform 500ms;
}

.libraryMobile.row {
  margin-top: 26px;
}

@media (max-width: 991px) {
  .fixed-top.rights {
    display: none;
  }

  .formcol {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 1024px) {
  .content {
    height: 80px;
  }

  .libraryMobile.row {
    display: none;
  }
}

@media (max-width: 400px) {
  .content {
    height: 60px;
  }
}
