@import '../Style/Color.scss';
@import '../Style/Typography.scss';

.storyImg.img-fluid {
  filter: drop-shadow(0px 7px 13px rgba(0, 0, 0, 0.25));
  width: 100px;
}

.myStories {
  padding-left: 1rem;
  padding-right: 1rem;
}

.storyCard.card {
  background-color: #f6f6f6;
  padding: 15px;
}

.storyTitle {
  color: #3b5260;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600;
}

.storyCard .text-muted {
  color: #949ea5 !important;
  font-size: 14px;
  font-weight: 400;
}

.storyCardLeft {
  padding-right: 0px !important;
}

.storyCardRight {
  padding-left: 0px !important;
}

.storyBtn > .float-right {
  display: flex;
}

.upan-primary.btn,
.upan-secondary.btn {
  background-color: $color-main;
  color: #fff;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  display: block;
  box-shadow: 0px 3px 6px #bdbdbd;
  margin-bottom: 15px;
}

.upan-secondary.btn {
  background-color: transparent;
  border: 1px solid $color-main;
  color: $color-main;
  padding: 9px 20px;
  float: right;
}

.upan-secondary.btn:hover,
.upan-secondary.btn:active,
.upan-secondary.btn:focus {
  background-color: #e4e4e4 !important;
  border: 1px solid $color-main !important;
  color: $color-main !important;
}

.upan-primary.btn:hover,
.upan-primary.btn:active,
.upan-primary.btn:focus {
  background-color: #e0610e !important;
}

.upan-primary.btn:active,
.upan-primary.btn:focus {
  box-shadow: 0px 2px 2px #bdbdbd !important;
}

.upan-secondary.btn:active,
.upan-secondary.btn:focus {
  box-shadow: 0px 2px 2px #bdbdbd !important;
}

.menuDotBtn.btn {
  padding: 0px;
  padding-left: 8px;
  border-radius: 50%;
  padding-right: 8px;
  border: none;
  background: transparent;
}

.menuDotBtn.btn:hover,
.menuDotBtn.btn:focus,
.menuDotBtn.btn:active {
  background-color: #cdcdcd !important;
  border: none;
  box-shadow: 0px !important;
}
.nav-tabs .nav-link {
  font-size: 19px;
  font-weight: 500;
  color: #bababb;
}
.nav-tabs .nav-link.active {
  font-weight: 500;
  font-size: 19px;
}
// nav-tabs
.nav-tabs .nav-link.active,
.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
}

#deleteModal .btn.btn-danger {
  background: #f55e5e;
  color: white;
  padding: 0px 10px;
}
#deleteModal .btn.btn-secondary {
  // background: #f55e5e;
  color: #545454;
  padding: 0px 10px;
}
#deleteModal .btn.btn-secondary:hover {
  // background: #f55e5e;
  color: white;
  // padding: 0px 10px;
}

#deleteModal .modal-header {
  color: #f55e5e;
}

// Search Suggestions

.searchSugg.backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  top: 0;
  width: 100%;
  height: 100%;
}

.searchSuggCard {
  background-color: white;
  margin-top: 100px;
}
.wtCategory.display-flex.mt-5.mb-5 img {
  height: 150px;
}
.wtCategory.display-flex.mt-5.mb-5 {
  flex-direction: row;
}

.published-badge {
  font-size: 12px;
  display: table;
  font-weight: 400;
  margin: auto;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .float-right > .buttons {
    display: none;
  }

  .storyBtn {
    position: absolute;
    right: 0;
    top: 41%;
  }
  .wtCategory.display-flex.mt-5.mb-5 {
    flex-direction: column;
    div {
      margin: auto !important;
      padding-bottom: 15px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
}

@media (min-width: 768px) and (max-width: 865px) {
}

@media (max-width: 767px) {
  .storyCard {
    padding-left: 50px;
    padding-right: 50px;
  }
  .wtCategory.display-flex.mt-5.mb-5 img {
    height: 110px;
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .storyCardLeft {
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .storyBtn.m-auto.col-1.col-lg-4.col-xl-5 {
    position: absolute;
  }

  .buttons.me-4 {
    display: none;
  }
}

@media (max-width: 585px) {
  .storyBtn {
    padding: 0px;
  }
}

@media (max-width: 400px) {
  .storyCard > .row > .col-12.col-lg-8.col-xl-7 {
    padding: 0;
  }

  .storyImg.img-fluid {
    width: 110px;
  }

  .storyCardRight.col {
    padding: 0;
  }

  .storyTitle {
    font-size: 1.35rem;
  }

  .text-muted.h6 {
    font-size: 0.9rem;
  }
}
