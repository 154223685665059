.adminside.category > .row > .col-12.col-lg-3.col-xl-2 {
  padding-left: 20px;
  padding-right: 20px;
}
.adminside {
  .logo {
    margin: 0;
    margin-top: 30px;
  }

  .col-12.col-lg-9.col-xl-10.bg-light {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pageHead {
    margin-top: 30px;
  }
  .card.border-0 {
    box-shadow: 0 3px 7px 0px #00000017;
    border-radius: 10px;
    padding: 20px 30px;
  }
  input.form-control[type='file'] {
    border: 1px solid #ced4da;
    padding: 0;
  }
  input[type='file']::file-selector-button {
    border: none;
    height: 100%;
    background-color: #e8ebee;
    margin-right: 10px;
    font-size: 15px;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #fa7925;
    outline: 0;
    box-shadow: none;
  }
}

/*   Media Queries */

@media (min-width: 1025px) {
  .adminside.category,
  .adminside.category > .row {
    min-height: 100vh;
  }
  .logo {
    span.navbar-toggler-icon {
      display: none;
    }
  }
  .sideMenu {
    div#navbarSupportedContent {
      display: block;
    }
    margin-top: 30px;
    display: block;
    .nav-item {
      margin-right: 0;
    }
    a.nav-link {
      color: black;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      padding-top: 15px;
      font-weight: 500;
      padding-bottom: 15px;
      margin-bottom: 15px;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
    }
    a.nav-link.active {
      background-color: #ffe3cf;
      color: #fa7925;
    }
    a.nav-link:hover {
      background: #fff5ed;
    }
  }
}

@media (max-width: 1024px) {
  .col-12.col-lg-3.col-xl-2.adminsidebar {
    height: 60px;

    .logo {
      margin: 0 !important;
      display: flex;
      height: 100%;
      justify-content: space-between;
      img {
        max-width: 110px;
      }
      button.navbar-toggler {
        padding-right: 0px;
      }
      span.navbar-toggler-icon {
        display: block;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      }
    }

    div#navbarSupportedContent {
      z-index: 1;
      position: absolute;
      background: white;
      left: 0;
      padding: 20px;
      padding-top: 5px;
      box-shadow: 0px 6px 8px -4px #00000030;
      right: 0;
    }
    .sideMenu {
      a.nav-link {
        font-weight: 500;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
      }
      a.nav-link.active {
        color: #fa7925;
      }
      a.nav-link:hover {
        background: #fff5ed;
      }
    }
  }
}
