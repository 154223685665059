@import '../../Style/Typography.scss';

.editorTop {
  height: 100px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04);
}

.typewriter_area {
  margin: 30px 25px;
}

.containers.typewriter {
  background-color: #f6f6f6;
}

.lastSaved.ChapterName {
  color: #aeaeae;
  font-size: 15px;
  font-weight: 500;
}

.sidebar-and-editor-container {
  height: calc(100vh - 70px);
  margin: auto;
  margin-top: 70px;

  .sidebar {
    max-width: 400px;
    z-index: 2;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    &.close {
      max-width: 70px;
    }
  }

  .chapterName.drag-item {
    height: 135px;
    background: #eaeaea;
    padding-left: 20px;
    padding-right: 20px;
    color: #000000;
    margin-bottom: 8px;
    top: auto !important;
    left: auto !important;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

    .content-excerpt {
      color: #4f4f4f;
      font-weight: 400;
    }
    .publish-status {
      font-size: 12px;
      color: #828282;
      font-weight: 400;
    }
    .more-chapter-button {
      box-sizing: content-box;
    }
    .number {
      background-color: #a9a9a9;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      color: white;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
    &.active {
      font-weight: 500;
      background-color: white;
      .chapter-name-and-options {
        color: #d34949;
      }
      .number {
        background-color: #d34949;
      }
    }
  }
}

.card {
  .mtrd-button.delete {
    svg {
      width: 20px;
    }
    padding: 8px 12px;
    color: #d34949;
    background: transparent;
    &:hover {
      box-shadow: none;
    }
  }

  .card-body {
    padding-bottom: 8px;
  }
}

.editor {
  height: calc(100vh - 140px);
  margin: auto;
  max-width: 1000px;
}

@media (min-width: 0px) {
  .typewriter .containers {
    padding-bottom: 0px;
  }
}

.toolbar {
  margin: auto;
  border-style: unset;
}

.Publish {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.Publishbtn {
  width: 30%;
  margin: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  background-color: #fa7925;
  @include normalText(#ffffff);
}

.PublishDiv {
  display: inline;
}

.NovalName {
  padding: 0;
  @include heading2(#000000);
  font-weight: 500;
  font-size: 20px;
  background-image: url(../../Icons/pencil-create.svg);
  background-position: right 10px center;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 36px;
  background-size: 18px;
  line-height: 20px;
}

.NovalName:focus {
  outline: none;
}

.dictCard.card {
  padding: 10px 20px;
  margin-bottom: 10px;
  border: none;
  box-shadow: 0 2px 6px 4px rgba(0, 0, 0, 0.06);
}

.pubPreParent {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 25px;
}

.pubPreParent > button {
  display: block;
  margin-left: 20px;
}

.previewBtn.mtrd-button {
  position: absolute;
  right: 25px;
  bottom: 25px;
}

// .sideChapterbar {
//   border-radius: 8px;
//   position: relative;
// }

.mainRowWriter {
  background-color: #f6f6f6;
  height: 100vh;
  max-height: 100%;
}

.chapterNameParent {
  // height: 100%;
  .chapterListContainer {
    flex: 1;
    .chaptersList {
      overflow-y: auto;
    }
  }
  .infoAndButton {
    button {
      background-color: transparent;
      color: #d34949;
      padding: 16px 12px;
      svg {
        width: 20px;
      }
      &.mtrd-primary {
        color: black;
      }
      &:hover {
        box-shadow: none;
      }
    }
  }
  .edittocbtn {
    display: block;
  }
}

.dateInfo {
  // background-color: #f0dddd;
  // padding: 12px 16px;
  // border-radius: 8px;
  p {
    color: black;
    font-size: 13px;
    margin-bottom: 8px;
    &.font-weight-bold {
      margin-bottom: 0;
    }
  }
}

.toolbarParent.fixed-bottom {
  display: none;
}

.publishSpan {
  color: #fa7925;
  font-weight: 500;
}

.NovalName {
  background-image: url(../../Icons/pencil-create.svg);
  background-repeat: no-repeat;
}

.sidebarHeading {
  background-color: #f6f6f6;
  height: 80px;
  p {
    color: #a7a7a7;
    font-size: 14px;
  }
}

.chapters-modal .chapterName.drag-item {
  height: 56px;
  display: flex;
  background: #ffffff;
  align-items: center;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #000000;
  margin-bottom: 8px;
  border: 2px solid #eaeaea;

  .number {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: white;
    font-size: 14px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 8px;
    background-color: #d34949;
  }
}

.chaptersDraggable {
  .collapse {
    &.show {
      margin-bottom: 16px;
      margin-top: -16px;

      .card {
        border: 2px solid #eaeaea;
        border-top: none;
      }
    }
  }
}

.upanyas-rte {
  height: 100%;
  background-color: transparent;
  border: none;

  &-toolbar {
    background-color: white;
    margin-left: 0px;
    margin-right: 0px;
    padding: 15px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    border: none;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 8px 13px -10px rgba(0, 0, 0, 0.25);

    button {
      background: white;
      border: none;
      display: flex;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 8px !important;
      margin-right: 4px !important;
      &[class*='isActive'] {
        background-color: #eee;
      }
      span {
        width: 100%;
        height: 100%;
      }
    }

    [class*='ButtonGroup'] {
      margin-left: 0px !important;
      [class*='ButtonWrap']:last-of-type button {
        margin-right: 0px !important;
      }
    }

    span > span,
    select {
      border: 1px solid #eee;
      border-radius: 8px !important;
      &:focus-visible {
        outline: none !important;
      }
    }

    select {
      height: 40px;
      padding-left: 10px;
    }
  }

  &-editor {
    background-color: white;
    height: calc(100% - 90px);
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
    padding-bottom: 5px;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;

    [class*='DraftEditor'] {
      height: 100%;
    }
  }
}

@media (min-width: 1025px) {
  // .sideChapterbar {
  //   transform: translateX(0%) !important;
  // }

  .customBarParent {
    position: relative;
  }

  .customBar {
    margin: 0;
    position: absolute;
    display: flex;
    bottom: 15px;
    left: 0;
    right: 0;
  }
}
.menuDotType {
  display: none;
}
.sideBartypeClose {
  display: none;
}

@media (min-width: 501px) and (max-width: 1024px) {
  .col1011 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
}

.sidebarOptions {
  max-width: 70px;
  min-width: 70px;
  width: 100% !important;
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  .option {
    width: 100%;
    height: 80px;
    cursor: pointer;
    transition: all 0.3s;
    background-color: white;
    svg {
      transition: all 0.3s;
    }
    &:hover {
      background-color: #ffe7e7;
      svg {
        stroke: #d34949;
      }
    }

    &.active {
      background-color: #ffe7e7;
      svg {
        stroke: #d34949;
      }
    }
  }
}

.sidebarContent {
  z-index: 0;
  width: 330px !important;
  max-width: 330px;
  height: calc(100vh - 70px);
  transition: all 0.2s;
  overflow-y: auto;

  &.open {
    transform: translateX(0%);
  }
  &.close {
    z-index: 0;
    position: absolute;
    transform: translateX(-200%);
  }
}

.details-section {
  .content-image,
  .content-image > img {
    border-radius: 30px;
    width: 185px;
    height: 262px;
  }
  .content-image {
    box-shadow: 0px 6px 16px -1px rgba(0, 0, 0, 0.28);
    img {
      object-fit: cover;
    }
  }

  .separator {
    width: 222px;
  }
  .language {
    span {
      color: #2f80ed;
    }
  }

  .title-and-explicit {
    word-break: break-all;
  }
  .content-title {
    font-size: 18px;
    font-weight: bold;
  }
  .explicit-tag {
    font-size: 12px;
    max-width: 64px;
  }

  .content-description > p {
    font-size: 14px;
  }

  .content-tags {
    span {
      display: block;
      font-size: 10px;
      font-weight: 700;
      border: 2px solid;
      border-radius: 50px;
    }
  }

  .table-of-content {
    .heading {
      font-size: 14px;
      font-weight: 700;
    }
    .chapter {
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    }
    .chapter:first-child {
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1),
        0px 1px 0px rgba(0, 0, 0, 0.1);
    }
    .chapter-name {
      font-size: 14px;
      font-weight: 600;
    }

    .published-date {
      color: #828282;
      font-size: 12px;
    }
  }
}

[aria-labelledby='chapterDropdown'] {
  box-shadow: 0px 10px 34px -10px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  .chapter-dropdown-item {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
  }
}

.more-chapter-menu {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  box-shadow: 0px 15px 38px -3px rgba(0, 0, 0, 0.25);
  font-size: 14px !important;
  border-radius: 8px !important;
  .more-chapter-menu-item {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (max-width: 1024px) {
  .col1011.col-10.col-lg-5 {
    padding-left: 0px;
    flex: 0 0 87.33333%;
    max-width: 87.33333%;
  }

  .editor {
    height: calc(93vh - 155px);
    width: 100%;
    &.story,
    &.poem {
      height: calc(100vh - 160px);
    }
  }

  .mainRowWriter {
    height: auto;
    background-color: #fff;
  }
  .pubPreParent.display-flex {
    display: none;
  }
  .sideBartypeClose {
    position: absolute;
    font-weight: 400;
    right: 20px;
    top: 10px;
    font-size: 34px;
    display: block;
  }

  .menuDotType {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 5px !important;
    display: block;
    transform: translateY(-50%);
    box-sizing: content-box;
    padding: 15px;
  }

  .NavRow.typewriter {
    height: 88px;
  }

  .toolbarParent.fixed-bottom {
    display: block;
  }

  // .sideChapterbar {
  //   position: absolute;
  //   height: 100%;
  //   z-index: 1031;
  //   box-shadow: 0px 2px 3px 4px #eee;
  //   transition: all 0.3s;
  //   transform: translateX(-100%);
  // }
  .editorRow {
    margin-top: 80px !important;
  }

  .chapterNameParent {
    margin-top: 40px;
  }

  .dictionaryCol {
    display: none;
  }
  .col-12.display-flex.m-auto {
    display: none;
  }
  .display-flex.position-absolute.w-100 {
    right: 0;
    padding-right: 35px;
    top: -5px;
  }
  .typewriter .backbtntype.col-1.m-auto.text-center img.btn {
    width: 27px;
  }
  .typewriter .backbtntype.col-1.m-auto.text-center {
    padding-left: 0px;
  }
  .typewriter .col-12.m-auto {
    margin-top: auto !important;
  }
  .previewBtn.mtrd-button {
    display: none;
  }
}

@media (max-width: 500px) {
  .NovalName {
    font-size: 1rem;
  }
  .ChapterName {
    font-size: 0.7rem;
  }
}

@media (max-width: 350px) {
  .NavRow.typewriter {
    height: 80px;
  }
  .customBarParent {
    padding-top: 0px;
  }
  .menuDotType {
    right: 0px;
  }
  .m-auto.text-center.backbtntype {
    padding-left: 0px;
  }
  .display-flex.position-absolute.w-100 {
    padding-right: 20px;
  }
}
