@import '../Style/Color.scss';
@import '../Style/Typography.scss';

.text-center.mainCardCard.card {
  height: 170px;
  width: 285px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 50px;
  border: 2px solid #eeeeee;
  border-radius: 10px;
}

.text-center.mainCardCard.card.active {
  border: 2px solid #fa7925;
}

.mainCardTitle {
  display: block;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 7px;
}

.mainCardNumber {
  display: block;
  margin-top: 7px;
  font-size: 27px;
  font-weight: 600;
}

.date-selecting-button {
  border: 2px solid #e5e5e5;
  padding: 0px 15px;
  font-size: 14px;
  border-radius: 6px;
  color: #333;
}

.border-container {
  border: 1px solid #e5e5e5;
  padding: 14px;
  border-radius: 4px;
  width: 100%;
}
