.NovelTag.badge {
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  margin-top: 10px;
  background-color: #434343;
  color: #fff;
  text-transform: uppercase;
}

div.upanExParent {
  display: flex;
  margin-left: 4px;
  margin-bottom: 6px;
}

div.upanExParent > .upanExTag {
  font-weight: bold;
  letter-spacing: 0.1rem;
  color: #383838;
  margin-left: 6px;
}
