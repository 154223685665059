@import '../Style/Color.scss';

.notifCard > .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 6px solid $color-main;
  padding: 10px 15px;
  background-color: #fdfdfd;
}

.notifCard > .card > p {
  font-size: 20px;
}

.notifDate {
  font-size: 15px;
  color: #424a51;
}

.notifClose.close {
  position: absolute;
  font-weight: 300;
  right: 20px;
  font-size: 34px;
}

.notifMain {
  margin-right: 3rem;
}

.mainCardRowNotif {
  margin-left: 0px;
  margin-right: 3rem;
}

.mainCardRowNotif.row {
  margin-left: 8px;
}

@media (max-width: 1024px) {
  .mainCardRowNotif.row {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .notifMain {
    margin-right: 0rem;
  }
}
