@import '../../Style/Typography.scss';

.NovelContainer {
  background: #f3f3f3;
  backdrop-filter: blur(100px);
  border-radius: 20px;
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 70px;

  @media (max-width: 991px) {
    margin-bottom: 20px;
    padding-left: 0;
    padding-bottom: 30px;
  }
}

.ImgContainer {
  filter: drop-shadow(0px 0px 26px rgba(36, 36, 36, 0.24));
}

img.NovelImgView {
  border-radius: 36px;
}
.NovelImgView {
  width: 100%;
  display: block;
  max-width: 228px;
  height: 300px;
  margin: auto;
  object-fit: cover;
  border-radius: 6px;
}

.buttonContainer {
  display: flex;
  margin-top: 40px;
}

.NovelNameHeading {
  margin-bottom: 0;
  // margin-top: 52px;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  color: #000000;
}
.UpanEx {
  padding-right: 12px;
}
.NovelDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #5b5b5b;
  text-align: justify;
}

.NovelTagParent {
  padding-top: 20px;
  padding-bottom: 26px;
}
.NovelTags {
  padding: 3px 13px;
  font-size: 14px;
  font-weight: 500;
  background: #ffffff;
  border-radius: 16px;
  margin-right: 10px;
  border: 2px solid black;
}

.fantasy {
  color: #d95757;
  border: 2px solid #d95757;
}
.romance {
  color: #1d67ab;
  border: 2px solid #1d67ab;
}
.scifi {
  color: #89931d;
  border: 2px solid #89931d;
}
.NovelAuthorDetail {
  padding-right: 5px;
  margin-bottom: 20px;
}

.NovelAuthor {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 1024px) {
    width: 45px;
    height: 45px;
  }
}
.NovelAuthorName {
  color: black;
  text-decoration: none;
  font-style: normal;
  font-size: 18px;
  font-weight: normal;
}
.NovelAuthorName:visited {
  color: black;
  text-decoration: none;
}

.NovelContent {
  position: relative;
}
.NovelCategory {
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 38px;
  padding: 5px 30px;
  font-weight: bold;
  font-size: 10px;
  color: #000000;
  margin-right: 10px;
  text-transform: uppercase;
}
.NovelVol {
  padding: 60px;
  background-color: #fbfff5;
}

.NovelVolDetail {
  margin: auto;
  text-align: center;

  padding-top: 10px;
  padding-right: 10px;
}
.NovelVolImg {
  width: 130px;
  filter: drop-shadow(0px 6px 4px #ccc);
}
.NovelVolCount {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #3b5260;
}

.NovelVolTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  padding: 5px 0px 0px 0px;
  margin: 0;
}
.NovelVolAuthor {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 5px;
  margin: 0;
  color: #6f7980;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;

  color: #000000;
}
.NovelCharacterDetail {
  padding: 60px;
}

.bigHeading {
  font-size: 25px;
  color: #000000;
  font-weight: bold;
}
.smallHeading {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.TopBar {
  height: 70px;
  width: 100%;
  z-index: 10;
  background: white;
  border-bottom: 2px solid #eee;
  transition: all 0.2s;
  .backButton {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    white-space: nowrap;
    overflow: hidden;
  }
  .container-fluid {
    display: flex;
    height: 100%;
  }
}
.TopBar.scrolled {
  height: 0;
}

.minw-0 {
  min-width: 0;
}

.bottomNav {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.1);
}

.aboutNovel {
  h3 {
    font-size: 25px;
    color: #000000;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    text-transform: capitalize;
    color: #5b5b5b;
    line-height: 200%;
  }
}

.aboutAuthor {
  h3 {
    font-size: 25px;
    color: #000000;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: #717171;
  }
  .authorCard {
    padding: 15px;
    @media (min-width: 1024px) {
      box-shadow: 0px 9px 21px rgba(176, 176, 176, 0.15);
      border-radius: 15px;
    }
  }
}

.nav-item {
  margin-right: 30px;
}

.nav-pills .nav-link {
  color: #000;
  background: #ffffff;
  border: 1px solid #ababab;
  box-sizing: border-box;
  border-radius: 10px;
}
.nav-pills .nav-link.active {
  background: #ffffff;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  color: black;
}
.NovelReview {
  padding-top: 45px;
  h2 {
    font-weight: bold;
    font-size: 25px;
  }
}
.NovelReviewSection {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 40px;

  .moreBtn {
    padding: 0;
    min-width: 0;
  }
}
.NovelReviewUser {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;

  .comment-description {
    padding-right: 51px;
    p {
      font-size: 14px;
      color: #7d7d7d;
      margin-top: 19px;
    }
  }

  @media (max-width: 1024px) {
    & > .media-body > h5 {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.NovelAdd,
.NovelStart {
  font-size: 16px;
  letter-spacing: -0.03em;
}

.actionBar {
  padding: 20px 20px 20px 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 89px;

  @media (max-width: 991px) {
    flex-direction: row;
    height: 60px;
    width: 255px;
  }
}

.mobileSectionDivider {
  margin-left: -5%;
  width: 105vw;
  color: lightgray;
  border-top: 15px solid lightgray;
}

.smallRound {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.typeCard {
  background: #d34949;
  box-shadow: 7px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 0px 0px;
}

.viewProfile {
  color: #d34949;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.03em;
}

.NovelDetailWrapper {
  width: 95%;
  max-width: 1400px;
}

/*=======================Stat Card==========================*/
.statCardsParent {
  margin-top: 30px;
  display: flex;
}
.statCardsParent > .statCard {
  margin-right: 20px;
}
.statCardsParent > .statCard:nth-child(3) {
  margin-right: 0;
}
.statCardsParent.overflow {
  display: block;
}
.statCardsParent.overflow > .statCard {
  margin-right: 0;
  margin-bottom: 10px;
}
.statCard {
  border: none;
}

.commentInput {
  border-radius: 8px;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  padding: 0.8rem;
  &:focus-visible {
    outline: none;
  }
}
.commentContainer {
  align-items: center;
  @media (max-width: 1024px) {
    margin: 0;
    margin-bottom: 20px !important;
  }
}

.imgHolder {
  display: inline-block;
}
.imgHolder > img {
  width: 19px;
}

.statTitle {
  display: inline-block;
  margin-left: 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.authorDetails {
  margin: 'auto';
  padding: '0';
  max-width: '175px';
  margin-top: '15px';
}

.commentInputContainer {
  position: relative;
  .commentBtn {
    border-radius: 50%;
    position: absolute;
    bottom: 37.07px;
    right: 33px;
    padding: 10px;
    min-width: 0;
    width: 50px;
  }
  textarea {
    resize: none;
    height: 157.8px;
  }

  .LoginToWrite {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(9px);
    border-radius: 16px;

    h3 {
      font-size: 18px;
      line-height: 27px;
      font-weight: bold;
      letter-spacing: -0.03em;

      a {
        text-decoration-line: underline !important;
        color: #d34949;
      }
    }
  }
}

.novelType {
  background: #d34949;
  border-radius: 0px 20px 0px 10px;
  height: 40px;
  width: 136px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.languageTag {
  color: #2f80ed;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
}

.ageGroup {
  background: #101010;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 5px 14px;
  margin-left: 20px;
  position: absolute;
}

.NoComment {
  background-image: url('../../Images/NoComment.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 345.8px;
}

.actionBtn {
  padding: 0;
  min-width: 0px;
}

/*==========================Media Queries======================*/

@media (max-width: 1024px) {
  .containers {
    padding-bottom: 100px;
    &.publishform {
      padding-bottom: 0px;
    }
  }
  .NovelCategoryParent {
    position: relative;
  }
}
