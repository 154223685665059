$primary-color: #d34949;
$primary-active-color: #bf3e3e;
$outline-color: #fafafa;
$outlilne-border-color: #d0d0d0;
$secondary-color: #f8f8f8;
$secondary-hover-color: #ffe7e7;
$secondary-active-color: #ffc3c3;
$black: #181818;

// ================Colors================

.mtrd-text-primary {
  color: $primary-color !important;
}

// ================Buttons================

.mtrd-button {
  border: 2px solid transparent;
  padding: 9px 30px;
  border-radius: 50px;
  font-weight: 600;
  line-height: 22px;
  font-size: 14px;
  transition: all 0.3s;
  min-width: 130px;

  &.small {
    min-width: 68px;
    padding: 4px 12px;
  }
  &.only-icon {
    min-width: auto;
    height: 45px;
    width: 45px;
    padding: 0px;
  }

  &:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  }

  &:active {
    box-shadow: none;
  }

  &[disabled='disabled'],
  &[disabled],
  &:disabled {
    opacity: 0.7;
  }
}

.mtrd-primary {
  color: white;
  background-color: $primary-color;

  &:hover {
    background-color: $primary-color;
  }

  &:active {
    background-color: $primary-active-color;
    box-shadow: inherit;
  }
}

.mtrd-secondary {
  color: $primary-color;
  background-color: $secondary-color;

  &:hover {
    background-color: $secondary-hover-color;
    box-shadow: 0px 3px 7px -3px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: $secondary-active-color;
    box-shadow: inherit;
  }
}

.mtrd-outlined {
  color: $black;
  background-color: $outline-color;
  border: 2px solid $outlilne-border-color;

  &:hover {
    border-color: $primary-color;
    color: $primary-color;
    box-shadow: 0px 3px 7px -3px rgba(0, 0, 0, 0.25);
    background-color: white;
  }

  &:active {
    border-color: $primary-active-color;
    color: $primary-active-color;
    box-shadow: inherit;
  }
}

.mtrd-transparent {
  color: $black;
  background-color: transparent;
  border: 2px solid transparent;

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
}

// ================Tags================

.mtrd-tag {
  font-size: 13px;
  min-width: 80px;
  padding: 6px 20px;
  box-shadow: none !important;
  font-weight: 700;

  &.mtrd-primary {
    color: $primary-color;
    border: 2px solid $primary-color;
    background-color: white;
    &:hover {
      background-color: $secondary-hover-color;
    }
  }
  &.mtrd-secondary {
    background-color: $secondary-hover-color;
    &:hover {
      background-color: $secondary-active-color;
    }
  }
}

// ================Tooltip================

.mtrd-tooltip {
  width: 150px;
  visibility: hidden;
  height: 40px;
  background-color: #454f5b;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  bottom: 125%;
  opacity: 0;
  transition: opacity 0.3s;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}

// ================Input================

.mtrd-input {
  outline: none;
  width: 100%;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  height: 60px;
  border: 2px solid #ececec;
  border-radius: 12px;
  transition: all 0.2s;
  font-weight: 500;
  color: #333333;

  &-label {
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 500;
  }
  &::placeholder {
    color: #a4a4a4;
  }

  &.invalid {
    border-color: red;
    box-shadow: none;
    &::placeholder {
      color: red;
    }
  }

  &:hover {
    box-shadow: 0px 4px 10px -1px rgb(0 0 0 / 6%);
  }
  &:focus {
    border-color: $primary-active-color;
    box-shadow: 0px 4px 7px 2px rgba(211, 73, 73, 0.15);
  }
}

textarea.mtrd-input {
  height: auto;
}

// Navs

.mtrd-navs {
  display: flex;
  margin-bottom: 20px;
  transition: all 0.3s;

  .nav-tab-item {
    margin-right: 30px;
    font-size: 18px;
    font-weight: 500;
    color: gray;
    transition: all 0.3s;
    cursor: pointer;
    &.active {
      color: $primary-color;
    }
  }
}

// Select

.mtrd-select {
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  .mtrd-select-placeholder {
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      background-image: url(./Images/chevron-down.svg);
      background-repeat: no-repeat;
      background-position-x: calc(100% - 12px);
      background-position-y: center;
      width: 48px;
      height: 48px;
      right: 0;
      transform: rotate(180deg);
      transition: all 0.2s;
    }

    &:hover {
      box-shadow: 0px 4px 10px -1px rgb(0 0 0 / 6%);
    }
    &.open {
      &::before {
        transform: rotate(0deg);
        right: -3px;
      }
      border-color: $primary-active-color;
      box-shadow: 0px 4px 7px 2px rgba(211, 73, 73, 0.15);
    }
  }

  .mtrd-select-items {
    position: absolute;
    border: 2px solid #e5e5e5;
    border-radius: 12px;
    background: white;
    width: 100%;
    top: 95px;
    padding: 8px 0px;
    overflow: auto;
    box-shadow: 0px 3px 9px -2px rgb(0 0 0 / 42%);
    z-index: 10;

    .mtrd-select-item {
      padding-left: 24px;
      padding-right: 24px;
      height: 40px;
      display: flex;
      align-items: center;
      transition: all 0.2s;
      cursor: pointer;

      &.selected {
        background-color: #f4f4f4;
      }
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
  }

  .mtrd-select-option-heading {
    padding-left: 24px;
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 13px;
    font-weight: 600;
    color: #acacac;
  }
}

// Tabs

.mtrd-tabs {
  display: grid;
  width: 100%;
  background: #f2f2f2;
  padding: 8px;
  position: relative;
  border-radius: 30px;
  &:after {
    content: '';
    background-color: white;
    height: calc(100% - 16px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }
  .tab-item {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.3s;
    background-color: transparent;
    z-index: 1;
    color: black;
    font-weight: 600;
    cursor: pointer;
    &.active {
      color: $primary-color;
    }
  }
}
