.profilePage {
  margin-top: -42px;
  .profileCover {
    position: relative;
    .coverImage {
      height: 270px;
      width: 100%;
      object-fit: cover;
    }

    .dpImage {
      position: absolute;
      height: 160px;
      bottom: -70px;
      width: 160px;
      left: 45px;
      filter: drop-shadow(0px -2px 5px #333);
    }
  }

  .profileDetails {
    margin-top: 90px;
    margin-left: 30px;
    p.profileRealName {
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 0;
    }
    p.profileUsername {
      margin-top: 6px;
      color: #4f4f4f;
    }
    button.followUserButton.upanyas-primary {
      margin-top: 10px;
    }

    // stat cards
    .profileStatCards {
      display: flex;
      .proStatCardParent {
        width: 33%;
        padding-right: 4%;
        .proStatCard {
          background-color: #fffbf9;
          border-radius: 14px;
          padding: 15px 20px;

          p.cardTitle {
            font-size: 18px;
            font-weight: 500;
            color: #fa7925;
          }

          .numAndImg {
            display: flex;
          }

          p.cardValue.mb-0 {
            font-size: 23px;
            font-weight: bold;
            text-transform: uppercase;
          }

          img.cardImg {
            margin-left: auto;
          }
        }
      }
    }

    //about
    .aboutUser.row {
      margin-top: 40px;
      padding-top: 33px;
      border-top: 3px solid #efefef;
      padding-bottom: 33px;
      border-bottom: 3px solid #efefef;
      margin-bottom: 40px;
      margin-right: 40px;
      p.aboutTitle {
        font-weight: 600;
        font-size: 26px;
      }
      p.aboutData {
        font-size: 17px;
        line-height: 31px;
      }
    }
    .userWork {
      margin-right: 40px;
      p.aboutTitle {
        font-weight: 600;
        font-size: 26px;
      }
      img.moreBtn {
        margin-bottom: 1rem;
        margin-left: auto;
        transform: rotate(180deg);
        width: 16px;
      }
      .workCards {
        .w-100.ContentCard {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .profileStatCards {
    margin-top: 30px;
  }
}

@media (max-width: 650px) {
  .profilePage {
    .profileCover {
      .dpImage {
        left: calc(50% - 80px);
      }
    }
    .profileDetails {
      .nameBlock {
        text-align: center;
      }
      margin-left: 12px;
      .profileStatCards {
        display: block;
        .proStatCardParent {
          width: 100%;
          margin-bottom: 15px;
        }
      }
      .aboutUser.row {
        margin-right: 15px;
        margin-left: 0;
      }
      .userWork {
        margin-right: 15px;
        margin-left: 0;
      }
    }
  }
}
