.readingSection.row {
  min-height: calc(100vh - 80px);
  max-height: 100%;
  margin-top: 80px;
  z-index: 11;
  background-color: #f6f6f6;
}

.col-lg-1.sidemenu {
  min-height: 100%;
  position: fixed;
  z-index: 12;
  user-select: none;
  /* standard syntax */
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}
.col-lg-1.sidemenu > .sidemenuMain {
  min-height: 100%;
  position: fixed;
  width: 100px;
  box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.readingCanvasRow.row {
  height: 100%;
  padding-top: 30px;
}

.col-lg-10.readingCanvas {
  background: #fff;
}

.tocParent.col-lg-4.col-xl-3 {
  position: fixed;
  width: 100%;
  background: white;
  height: 100%;
  z-index: 1;
  margin-left: 115px;
  box-shadow: 0px 3px 12px -2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  transform: translateX(-100vw);
}

span.sidemenuIcons {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
}
.tocIconParent {
  margin-top: 50px;
}

span.sidemenuIcons:hover {
  background-color: #f6f6f6;
}

.tocTable {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
}

span.tocheading {
  font-size: 23px;
  font-weight: 600;
}
.tocitems {
  margin-top: 30px;
}

span.tocitem {
  display: block;
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 10px;
  color: #889197;
}

span.tocitem.active {
  color: #3b5260;
}

.float-bottom {
  position: absolute;
  display: block;
  bottom: 130px;
  width: 100%;
}
img.iconwidth {
  width: 25px;
}

.readingMaterial {
  padding: 40px 30px;
  line-height: 30px;
  text-align: justify;
  overflow: hidden;
}
.readingMaterial p {
  font-size: 19px;
}

// For settings popup
.settingsMenu {
  position: absolute;
  left: 120px;
  width: 300px;
  height: 500px;
  background: white;
  top: 111px;
  box-shadow: 0px 1px 34px -12px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  z-index: 20;
  transition: all 0.3s;
  // transform: translateX(-200%);
  display: none;
}
.settingsMenu::after {
  content: '';
  position: absolute;
  top: 23px;
  left: -6%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

.sizeSliderParent > .css-yvszuv-Slider {
  width: 100%;
}
.sizeSliderParent > .css-yvszuv-Slider > div:first-of-type {
  background-color: #fa7925;
}

.whitebg,
.yellowbg,
.darkbg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #aaa;
  background: white;
}

.darkbg {
  background-color: #575757;
}
.yellowbg {
  background-color: #faf1a3;
}

.whitebg:focus,
.yellowbg:focus,
.darkbg:focus {
  outline: none;
}

//for changing bg colors

// dark
.readingSection.row.dark,
.sidemenuMain.dark span.sidemenuIcons:hover {
  background-color: #121212;
}
.col-12.col-lg-10.offset-lg-1.readingCanvas.dark,
.col-lg-1.sidemenu > .sidemenuMain.dark,
.tocParent.col-lg-4.col-xl-3.dark,
.readsetmodal.dark,
button.profileDropdown.dark,
button.upanyas-secondary.me-4.addToLib.dark {
  background-color: #1d1d1d;
  color: #fff;
}
.readsetmodal.dark .close,
.sideBartypeClose.close.dark {
  color: #fff;
}

.tocParent.col-lg-4.col-xl-3.dark,
.settingsMenu.dark,
button.profileDropdown.dark:hover,
button.profileDropdown.dark:focus,
button.upanyas-secondary.me-4.addToLib.dark:hover {
  background-color: #252525;
}
.settingsMenu.dark::after {
  border-color: transparent #252525 transparent transparent;
}
.settingsMenu.dark {
  border: 1px solid #2e2e2e;
}
.col-12.sidemenuBottom.dark {
  background-color: #1d1d1d;
  border-color: #2c2c2c;
}
.container-fluid.NavMain.fixed-top.dark,
img.btn.backIcon.dark {
  background: #1d1d1d;
  border: 1px solid #1d1d1d;
  color: #fff;
}
span.ChapterName.dark {
  color: #929292;
}
span.NovalName.dark {
  color: #fff;
}
.sizeSliderParent > .css-yvszuv-Slider > div:first-of-type.white,
.sizeSliderParent > .css-yvszuv-Slider > div:first-of-type.dark {
  background-color: #fa7925;
}

//white
.readingSection.row.white {
  background-color: #f6f6f6;
}
.readsetmodal.white .close,
.sideBartypeClose.close.white {
  color: #000;
}
.col-12.col-lg-10.offset-lg-1.readingCanvas.white,
.col-12.sidemenuBottom.white,
.settingsMenu.white,
.readsetmodal.white,
button.profileDropdown.white {
  background-color: #fff;
  color: #000;
}
.col-12.sidemenuBottom.white {
  background-color: #fff;
  border-color: #eee;
}
button.profileDropdown.white:hover,
button.profileDropdown.white:focus {
  background-color: #eee;
}
.col-lg-1.sidemenu > .sidemenuMain.white,
.tocParent.col-lg-4.col-xl-3.white,
button.upanyas-secondary.me-4.addToLib.white {
  background-color: #fff;
}
.settingsMenu.white {
  border: none;
}
.settingsMenu.white::after {
  border-color: transparent white transparent transparent;
}
.container-fluid.NavMain.fixed-top.white,
img.btn.backIcon.white {
  background: #fff;
  border: 1px solid #eee;
  color: #000;
}
img.btn.backIcon.white {
  border: 1px solid transparent;
}
button.upanyas-secondary.me-4.addToLib.white:hover {
  background-color: #eee;
}

.container-fluid.NavMain.fixed-top.dark {
  border-bottom: 2px solid #2c2c2c;
}

//yellow
.readingSection.row.yellow {
  background-color: #f1e5ca;
}
.readsetmodal.yellow .close,
.sideBartypeClose.close.yellow {
  color: #000;
}
.col-12.col-lg-10.offset-lg-1.readingCanvas.yellow,
.col-lg-1.sidemenu > .sidemenuMain.yellow,
.tocParent.col-lg-4.col-xl-3.yellow,
.sidemenuMain.yellow,
.settingsMenu.yellow,
.readsetmodal.yellow,
button.profileDropdown.yellow {
  background-color: #fbf0d9;
  color: #5f4b32;
}
.sizeSliderParent > .css-yvszuv-Slider > div:first-of-type.yellow {
  background-color: #5f4b32;
}
button.profileDropdown.yellow:hover,
button.profileDropdown.yellow:focus {
  background-color: #f1e5ca;
}
.settingsMenu.yellow {
  border: 1px solid none;
}
.col-12.sidemenuBottom.yellow {
  background-color: #fbf0d9;
  border-color: #ebe0c8;
}
.settingsMenu.yellow::after {
  border-color: transparent #fbf0d9 transparent transparent;
}
.container-fluid.NavMain.fixed-top.yellow,
img.btn.backIcon.yellow {
  background: #fbf0d9;
  border: 1px solid #fbf0d9;
  color: #000;
}
.container-fluid.NavMain.fixed-top.yellow {
  border-bottom: 2px solid #ebe0c8;
}

span.ChapterName.yellow {
  color: #988a79;
}

span.NovalName.yellow {
  color: #5f4b32;
}
button.upanyas-secondary.me-4.addToLib.yellow,
button.upanyas-secondary.me-4.addToLib.yellow:hover {
  background-color: #fbf0d9;
  border: 1px solid #5f4b32;
  color: #5f4b32;
}

//for quill editor css
.ql-align-center {
  text-align: center;
  margin-bottom: 0rem;
}

.ql-font-poppins {
  word-spacing: 3px;
}

.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

//for word-meaning
div#word-meaning {
  background: #454f5b;
  color: #fff;
  padding: 10px 10px;
  box-shadow: 0 0 15px -4px #505050;
  border-radius: 5px;
  width: 300px;
  z-index: 12;
}
.speech-bubble {
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid #4e5a67;
  margin-bottom: 5px;
}
span.wmheading {
  background: #2e353e;
  padding: 3px 10px;
  border-radius: 4px;
}
span.mainword {
  font-size: 18px;
  text-transform: capitalize;
}

.forLaptop.col-5.col-lg-5.col-xl-5 {
  display: block;
}
.ChapterName {
  display: block;
}
.dropdown.mobileDropDown {
  display: none;
}
.col-12.sidemenuBottom {
  display: none;
}
.col-12.col-lg-10.offset-lg-1.readingCanvas {
  margin-bottom: 20px;
}
div#exampleModal {
  display: none;
}

.meaningOfWord {
  font-style: italic;
  font-size: 14px;
}

.reading-progress {
  width: 0%;
  height: 4px;
  background-color: #fa7925;
  z-index: 1031;
  box-shadow: 0 2px 3px -1px #fa7925;
  position: fixed;
  transform: translateY(-1px);
  max-width: 1400px;
}
@media (max-width: 1024px) {
  .readersec .NovalName {
    line-height: 2.4rem;
    font-size: 1.2rem;
  }
  .NavRow.readersec {
    height: 70px;
  }

  .modal-content.readsetmodal {
    padding: 12px 15px;
  }
  .readsetmodal .modal-header {
    border: 0;
  }
  .readsetmodal .sizeSliderParent {
    margin-bottom: 20px;
    font-weight: 500;
  }

  .readsetmodal .bgParent {
    margin-bottom: 12px;
    margin-top: 28px;
    font-weight: 500;
  }
  .sizeSliderLabel {
    margin-bottom: 13px;
  }
  .bgLabel {
    margin-bottom: 20px;
  }
  .readingCanvasRow.row {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
  }
  .forLaptop.col-5.col-lg-5.col-xl-5 {
    display: none;
  }
  .ChapterName {
    display: none;
  }
  .readingSection.row {
    margin-top: 72px;
  }
  .dropdown.mobileDropDown {
    display: block;
    top: 0;
    width: 0;
  }
  .menuDotType.readsecdropdown {
    padding: 10px 20px;
    box-sizing: content-box;
    top: 22%;
  }
  .col-12.col-lg-1.sidemenu {
    display: none;
  }
  .tocParent.col-11.col-lg-4.col-xl-3 {
    margin: 0;
  }
  .col-12.sidemenuBottom {
    position: fixed;
    width: 100%;
    background: white;
    height: 60px;
    bottom: 0;
    border-top: 2px solid #eeeeee;
    padding: 0;
    margin-left: 15px;
    display: block;
  }
  .col-12.col-lg-10.offset-lg-1.readingCanvas {
    margin-bottom: 0px;
  }
  .readingMaterial {
    padding-bottom: 70px;
  }
  .sidemenuBottom > .container-fluid.h-100 > .row.h-100 > .col {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
@media (max-width: 500px) {
  .readersec .NovalName {
    line-height: 2.4rem;
    font-size: 1rem;
  }

  .readersec img.btn.backIcon {
    padding-right: 0;
    padding-left: 12px;
  }
  .readersec .col-1.m-auto.text-center.backbtntype {
    padding: 0;
  }
  .readingMaterial {
    padding-left: 0;
    padding-right: 0;
    text-align: justify;
  }
}
@media (max-width: 350px) {
  .NavRow.readersec {
    height: 70px;
  }
}
