//Theme Colors
$color-main: #fa7925;
$color-light: #fff3ec;
$color-sec: #fff7f2;
$color-accent: #ffe0cc;
$general-lightgray: #f1f1f1;
//Font Colors
$font-lightgray: #8a8a8a;
$font-Dark: #000000;
$font-gray: #4e4e4e;
$font-lightDark: #626262;
$font-darkgray: #263238;
