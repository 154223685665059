.basedOnLikesSection {
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
  }
}

.stats {
  display: flex;
  p {
    margin-right: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #a4a4a4;
  }
}

.bigNovelCard {
  padding: 15px 0 20px 18px;
  border-bottom: 1px solid rgb(149 149 149 / 25%);
  margin-bottom: 18px;
  position: relative;

  .novelImage {
    border-radius: 10px;
    width: auto;
    height: 160px;
    box-shadow: 0px 10px 20px -7px rgba(0, 0, 0, 0.15);
  }

  &::after {
    content: attr(data-type);
    text-transform: uppercase;
    background: #d34949;
    color: white;
    position: absolute;
    right: 0;
    border-radius: 0px 10px;
    padding: 8px 27px;
    font-size: 14px;
    font-weight: 600;
  }
}

.novelTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.novelAuthor {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #8e8e8e;
}

.description {
  font-size: 14px;
  line-height: 150%;
  text-transform: capitalize;
  color: #969696;
  margin-bottom: 0;
}

.showMore {
  color: #d34949;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-decoration-line: underline !important;
}
