.NavMain {
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid #eee;
  transition: all 0.3s;
  margin: auto;

  .NavRow {
    height: 70px;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: auto;

    .NavLogo {
      display: block;
      margin: 0;
      text-align: center;
      margin-left: 15px;

      img {
        width: 130px;

        @media screen and (max-width: 1024px) {
          width: auto;
          height: 40px;
        }
        @media screen and (max-width: 576px) {
          width: auto;
          height: 30px;
        }
      }
    }

    .nav-menu-items {
      .NavUl {
        margin: 0;
        list-style: none;
        display: flex;
        padding: 0;
        justify-content: center;

        .NavLink {
          // margin: auto 35px;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          text-decoration: none;

          a.active {
            font-weight: 600;
            border-bottom: 3px solid var(--orange);
          }
        }
      }
    }
    .profile {
      height: 30px;
      width: 30px;
    }

    .btn.button1 {
      margin: auto 10px;
      padding: 5px 14px !important;
      border-radius: 50% !important;
      background-color: white;
      border: none;
      color: white;

      &:active {
        background-color: #eee;
      }
      &:focus {
        box-shadow: none !important;
        background-color: #eee;
        border: none;
      }
      &:hover {
        background-color: white;
      }
    }
  }

  &.bg-transparent {
    background-color: transparent;
    border-bottom: none;
    .NavRow {
      .nav-menu-items {
        .NavUl {
          .NavLink {
            color: #ffffff;
          }
        }
      }
      #profileDropdown {
        color: #ffffff !important;
      }
    }
    .logo-and-search-bar-container {
      input {
        background-color: transparent;
        color: #fff;
        border: 1px solid #464646;
        &::placeholder {
          color: #fff;
        }
      }
    }
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 90%;
  background-color: rgb(255, 255, 255);
  border-radius: 5%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
}

.drpul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.drpli {
  padding: 8px 12px;
  font-weight: normal;
  font-size: 1rem;
  color: #f5f5f5;
}

.drpli:hover {
  background-color: #fa7925;
  color: white;
  cursor: pointer;
}

.drplink {
  font-weight: normal;
  font-size: 1rem;
  color: rgb(0, 0, 0);
}

.drplink:hover {
  font-weight: normal;
  font-size: 1rem;
  color: rgb(0, 0, 0);
}

.user-name {
  font-size: 15px;
}
.user-email {
  font-size: 13px;
}

.logout-item {
  background-color: #f2f2f2 !important;
  font-size: 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.navbar-dropdown {
  border: 1px solid rgba(123, 123, 123, 0.12);
  border-radius: 13px;
}

.writeModalTrigger {
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .NavUl {
    padding: 0px;
  }
}

@media (max-width: 1199px) {
  .user-name {
    display: none;
  }
}

@media (max-width: 1024px) {
  .container-fluid.NavMain.fixed-top.laptopview {
    display: none;
  }

  .profile {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    margin: 0px;
  }
}
