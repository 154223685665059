.main {
  background-color: #ffe0cc;
  // position: absolute;
  width: 100%;
  // margin-left: -20px;
}

.Footer {
  display: flex;
  padding: 50px;
}

.fimg {
  width: 35%;
  text-align: left;
}

.menu {
  margin: auto;
  display: block;
  position: relative;
}

.list1 {
  list-style: none;
}

.Link1 {
  text-align: center;
  margin: 10px 20px 5px 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 84.69%;
  color: black;
}

@media (max-width: 500px) {
  .Footer {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .Link1 {
    display: block;
    margin: 10px 20px 5px 50px;
  }

  .menu {
    margin-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .Link1 {
    display: inline;
    margin: 10px 20px 5px 25px;
  }
}

@media (max-width: 1024px) {
  .main {
    display: none;
  }
}

.Link1:hover {
  color: black;
}

.year {
  color: #fc4a1a;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 84.69%;
  padding-bottom: 20px;
}
