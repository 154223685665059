.mobileNavTop {
  display: none;
}

.mobileBottomIcon > span {
  width: 28px;
  height: 28px;
  margin: auto;
}

@media (max-width: 1024px) {
  .mobileNavTop {
    display: block;
    height: 60px;
  }

  .mobileNavIcon {
    width: 25px;
    margin-top: -5px;
  }

  .mobileNavTop.NavMain > .NavRow,
  .mobileNavTop.NavMain .NavRow {
    height: 60px;
  }

  .mobileNavTop.fixed-bottom {
    border-top: 3px solid #eee;
  }

  .mobileBottomIcon > span {
    display: block;
    margin-top: -4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 29px;
  }

  .mobileBottomIcon > .homeIcon {
    background-image: url(../Icons/home.svg);
  }

  .mobileBottomIcon.active > .homeIcon {
    background-image: url(../Icons/homeActive.svg);
  }

  .mobileBottomIcon > .pencilIcon {
    background-image: url(../Icons/pencil-create.svg);
  }

  .mobileBottomIcon.active > .pencilIcon {
    background-image: url(../Icons/pencil-createActive.svg);
  }

  .mobileBottomIcon > .bookIcon {
    background-image: url(../Icons/book.svg);
  }

  .mobileBottomIcon.active > .bookIcon {
    background-image: url(../Icons/bookActive.svg);
  }

  .mobileBottomIcon > .sideDashboard {
    background-image: url(../Icons/VectorN.svg);
  }

  .mobileBottomIcon.active > .sideDashboard {
    background-image: url(../Icons/Vector.svg);
  }

  .mobileBottomIcon > .searchIcon {
    background-image: url(../Icons/search.svg);
  }

  .mobileBottomIcon.active > .searchIcon {
    background-image: url(../Icons/searchActive.svg);
  }

  .mobileNavHeading {
    font-size: 21px;
    font-weight: 600;
  }
  .forBtnLogin {
    margin-top: 17px;
  }
}

@media (max-width: 500px) {
  .mobileBottomIcon > span {
    background-size: 22px;
  }

  .mobileNavIcon {
    width: 22px;
  }

  .mobileNavHeading {
    font-size: 18px;

    @media screen and (max-width: 576px) {
      font-size: 16px;
    }
  }
}
