// @import "../../Style/Typography.scss";
// @import "../../Style/Color.scss"

.Box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 0.8rem;
  padding: 18px;
}

.libImg {
  width: 127px;
  max-width: 100%;
  border-radius: 6px;
  filter: drop-shadow(0px 2px 2px #aaa);
}

.showMoreParent.homepage {
  position: absolute;
  right: 36px;
  top: -43px;
}

.homepagelibboxparent.col-12 {
  padding-left: 43px;
  padding-right: 43px;
}

@media (max-width: 1200px) {
  .homepagelibcard:nth-child(4) {
    display: none;
  }
}

@media (max-width: 1500px) {
  .homepagelibcard:nth-child(5) {
    display: none;
  }
}
