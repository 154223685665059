.LibCard {
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(1, 1, 1, 0.31);
  padding: 15px 23px;
  border-radius: 8px;
}

.LibImg {
  width: 120px;
  filter: drop-shadow(0px 2px 2px #aaa);
  border-radius: 5px;
}

.LibTitle {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 20px;
  margin-bottom: 0;
  color: #3b5260;
}

.LibTitle,
p.LibAuthor,
.libGridTitle,
.libGridAuthor {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mt-13 {
  margin-top: 1.3rem;
}

.mb-13 {
  margin-bottom: 1.3rem;
}

.LibCard .media-body {
  margin-left: 20px;
  overflow: hidden;
}

p.LibAuthor,
p.LibChapterDesc {
  color: #a2a4a5;
}

p.LibAuthor {
  font-size: 15px;
}

span.LibChapterName {
  color: #3b5260;
  font-size: 20px;
  font-weight: 500;
}

.display-flex {
  display: flex;
}

.RowBar {
  margin: auto;
  text-align: center;
  font-weight: 500;
}

.libGridImage {
  height: 150px;
  max-height: 100%;
  border-radius: 6px;
  margin-bottom: 11px;
  filter: drop-shadow(0px 2px 2px #aaa);
}

.libGridCard {
  margin: auto;
  text-align: center;
  overflow: hidden;
}

.libGridTitle {
  font-weight: bold;
  margin-bottom: 5px;
}

.libGridAuthor {
  opacity: 80%;
}

// @media (max-width: 500px) {}
@media (max-width: 600px) {
  .LibCard.media {
    display: block;
    padding: 25px 23px;
    // padding-bottom: 50px;
    // padding-top: 40px;
    text-align: center;
  }

  .LibCard .media-body {
    margin-left: 0px;
  }

  .LibImg {
    display: block;
    margin: auto !important;
    text-align: center;
  }

  .LibTitle {
    margin-bottom: 5px;
    margin-top: 10px;
  }
}

@media (max-width: 1024px) {
  .librarydiv.container-fluid {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
}

@media (max-width: 400px) {
  .librarydiv.container-fluid {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
