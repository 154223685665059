.content-container {
  width: 70%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.content-item {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}
