@import './Color.scss';

@mixin fontStyle($fontSize, $fontweight, $color, $lineHeight) {
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: $fontweight;
  font-size: $fontSize;
  color: $color;
  line-height: $lineHeight;
}

@mixin heading1($color: $font-Dark, $size: 2.1875rem, $weight: 600) {
  @include fontStyle($size, $weight, $color, 52px);
}

@mixin heading2($color: $font-Dark, $size: 1.675rem, $weight: Bold) {
  @include fontStyle($size, $weight, $color, 45px);
}

@mixin subtitle($color: $font-Dark, $size: 1.362rem, $weight: normal) {
  @include fontStyle($size, $weight, $color, 37px);
}

@mixin filedText($color: $font-lightDark, $size: 0.95rem, $weight: 500) {
  @include fontStyle($size, $weight, $color, 30px);
}

@mixin normalText($color: $font-gray, $size: 0.82rem, $weight: 500) {
  @include fontStyle($size, $weight, $color, 37px);
}
