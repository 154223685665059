@import '../../Style/Typography.scss';

.Socialf,
.Socialg {
  outline: none;
  width: 100%;
  height: 30%;
  padding: 6px;
  background-color: #f7f7f7;
  border: white;
  border-radius: 5px 5px 5px 5px;
  @include normalText(rgba(66, 66, 66, 0.6));
}

.Socialg {
  margin-top: 10px;
}

.Socialf {
  padding-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 40px;
}
