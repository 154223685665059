@import '../../Style/Typography.scss';

.catgoryPage {
  border-radius: 0.5rem;
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin: 1rem 0;
}

.catgory {
  border-radius: 1rem;
  width: 100%;
  min-height: 130px;
  text-align: center;
  margin-bottom: 20px;
  @include subtitle(white, 1.2em, 700);

  p {
    margin-left: 2rem;
    margin-right: 2rem;
    color: transparent;
  }
}

a:hover {
  text-decoration: none;
}
