.episode-container {
  box-shadow: 0px 1px 0px rgba(176, 176, 176, 0.25);
}

.SeriesCardImg {
  box-shadow: 0px 10px 20px -7px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 268px;
  width: 201px;
}

.episode-num {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #d34949;
}

.episode-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.episode-description {
  font-size: 14px;
  line-height: 150%;
  text-transform: capitalize;
  color: #969696;
}

.ReadBtn {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
}

.showMoreBtn {
  height: 50px;
  width: 190px;
  text-decoration-line: underline;
}
